import React, { useEffect, useMemo, useState } from "react";
import { Wrapper } from "./styles";
import Breadcrumb from "../../shared/Breadcrumbs";
import { Container, Box, Grid } from "@mui/material";
import Loading from "../../shared/Loading";
import getJira from "../../services/getJira";
import { useAdminState } from "../../context/AdminContext";
import { StarIcon, BuildingOffice2Icon } from "@heroicons/react/24/outline";
import DoughnutChart from "../../shared/DoughnutChart";
import BarChart from "../../shared/BarChart";
import getStatusCounts from "../../utils/statusCounts";
import Projects from "../DashboardItem/PMO/Projects";
import getCurrentMonthName from "../../utils/getCurrentMonth";
import ThisMonthGoLive from "../DashboardItem/PMO/ThisMonthGoLive";
import HighlightsProjects from "../DashboardItem/PMO/HighlightsProjects";
import Resources from "../DashboardItem/PMO/Resources";
import ResourceByDomain from "../DashboardItem/PMO/ResourcesByDomain";
import DemandProjects from "../DashboardItem/PMO/DemandProjects";
function PMODashboard() {
  const { jira, loading } = useAdminState().jira;
  const { resources } = useAdminState().resources;
  const { profile } = useAdminState().login.user || {};
  const [cancelled, setCancelled] = useState("");
  const [demand, setDemand] = useState([]);
  const [IPP, setIPP] = useState([]);

  useEffect(() => {
    if (!jira.length) {
      getJira(
        `(project = IPP AND duedate >= startOfYear() AND duedate <= endOfYear(+1y)) OR (project = DEM AND status NOT IN ("Done", "On Hold", "Cancel", "Submit To Portfolio"))`,
        "summary, status, duedate, assignee, customfield_10665, customfield_10675, customfield_10666, customfield_11779, customfield_10670, customfield_10678, customfield_10668, customfield_10671"
      );
    }
  }, [jira.length]);

  useEffect(() => {
    if (jira) {
      setDemand(jira.filter((i) => i?.key?.slice(0, 4) === "DEM-"));
      setIPP(jira.filter((i) => i?.key?.slice(0, 4) === "IPP-"));
    }
  }, [jira]);

  const useChartData = (dataKey, summary) => {
    return useMemo(() => {
      const statusCounts = getStatusCounts(jira, dataKey);
      const transformed = summary
        ? {
            "In Progress":
              (statusCounts["Active"] || 0) +
              (statusCounts["ACTIVE (Project Highlight)"] || 0) +
              (statusCounts["Open from DM (temp.)"] || 0),
            Completed: statusCounts["Completed"] || 0,
            "On Hold": statusCounts["On Hold"] || 0,
          }
        : statusCounts;

      const labels = Object.keys(transformed);
      const data = Object.values(transformed);

      const backgroundColors = summary
        ? ["rgb(67, 185, 178)", "#168ae9", "#9E9E9E"]
        : data.map((value) => {
            if (value < 5) return "rgb(253, 126, 64)"; // Slightly darker pastel pink
            if (value < 10) return "rgb(254, 200, 77)"; // Slightly darker pastel orange
            return "rgb(67, 185, 178)"; // Slightly darker pastel teal
          });

      summary && setCancelled(statusCounts.Cancelled);

      return {
        labels,
        datasets: [
          {
            data,
            backgroundColor: backgroundColors,
          },
        ],
      };
    }, [jira, dataKey, summary]);
  };

  const chartData = useChartData("status", true);
  const byDomainData = useChartData("customfield_10666", false);

  return (
    <Wrapper>
      <Container className="container">
        <div className="page-header">
          <h2>PMO Dashboard</h2>
          <div className="flex">
            <Breadcrumb page="PMO Dashboard" link="#" />
          </div>
          <p className="welcome">
            Hi, <span>{profile?.given_name}</span>. Welcome back to Project
            Status Automation report!
          </p>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <Box sx={{ flexGrow: 1 }}>
            <div className="dashboard-wrapper team-dashboard">
              <Grid container spacing={4}>
                <Grid item xs={12} md={5}>
                  <div className="summary-item project-sum">
                    <div className="flex">
                      <h4 className="no-border">
                        <StarIcon />
                        Project Summary
                      </h4>
                    </div>
                    <div className="detail-item flex no-border">
                      <div className="pie-chart-container total-projects">
                        <div className="total">
                          <h2>Total</h2>
                          <p>{IPP.length}</p>
                        </div>

                        {chartData ? (
                          <DoughnutChart data={chartData} />
                        ) : (
                          <p>No data available</p>
                        )}

                        <p className="cancelled">
                          Cancelled: <span>{cancelled}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={7}>
                  <div className="summary-item project-sum">
                    <div className="flex">
                      <h4 className="no-border">
                        <BuildingOffice2Icon />
                        By Domain
                      </h4>
                    </div>
                    <div className="detail-item flex no-border">
                      {byDomainData ? (
                        <BarChart data={byDomainData} />
                      ) : (
                        <p>No data available</p>
                      )}
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <DemandProjects demand={demand} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Resources />
                </Grid>

                <Grid item xs={12} md={6}>
                  <ResourceByDomain resources={resources || []} />
                </Grid>

                <Grid item xs={12}>
                  <Projects project={IPP} />
                </Grid>

                <Grid item xs={12}>
                  <HighlightsProjects project={IPP} />
                </Grid>

                <Grid item xs={12}>
                  <div className="summary-item">
                    <div className="flex">
                      <h4 className="no-border">
                        <StarIcon />
                        Project Go Live in {getCurrentMonthName()}
                      </h4>
                    </div>

                    <div className="detail-item flex no-border">
                      <ThisMonthGoLive project={IPP} />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Box>
        )}
      </Container>
    </Wrapper>
  );
}

export default PMODashboard;
