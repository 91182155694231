import React, { useMemo } from "react";
import Slider from "react-slick";
import { Box, Paper } from "@mui/material";
import { formatDate } from "../../../utils/checkDate";
import { Link } from "gatsby";

function HighlightsProjects({ project }) {
  const highlightedProjects = useMemo(
    () =>
      project.filter((i) =>
        i?.fields?.status?.name.includes("Project Highlight")
      ),
    [project]
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const extractColor = (str) => {
    const match = str.match(/\((.*?)\)/);
    if (match) {
      return match[1].toLowerCase();
    }
    return "";
  };

  return (
    <Box className="highlight-projects">
      <Slider {...settings}>
        {highlightedProjects.map((item) => {
          const { id, fields } = item;
          const {
            summary,
            duedate,
            customfield_10665,
            customfield_10670,
            customfield_10678,
            customfield_10666,
            customfield_10671,
          } = fields;

          return (
            <Box key={id} p={1}>
              <Paper
                elevation={0}
                sx={{ display: "flex", gap: 2, alignItems: "stretch" }}
                className="wrapper"
              >
                <div
                  style={{ flex: "0 0 40%" }}
                  className={`${extractColor(
                    customfield_10678?.value || ""
                  )} highlight-bg left-section`}
                >
                  <div className="top-section">
                    <h3>{summary}</h3>
                    <p>
                      Risk Status:{" "}
                      <span
                        className={`${extractColor(
                          customfield_10678?.value || ""
                        )}`}
                      />
                    </p>
                  </div>
                </div>

                <div style={{ flex: "1" }} className="right-section">
                  <div className="middle-section">
                    <div className="status">{customfield_10670?.value}</div>
                    <h5>
                      Domain: <span>{customfield_10666?.value}</span>
                    </h5>

                    <h5>
                      Country: <span>{customfield_10665?.value}</span>
                    </h5>
                    <h5>
                      Project Manager: <span>{customfield_10671 || "N/A"}</span>
                    </h5>
                    <h5>
                      Due Date: <span>{formatDate(duedate)}</span>
                    </h5>
                  </div>
                  <div className="view-detail">
                    <Link href={`/project-details/pmo?id=${item.key}`}>
                      View Detail
                    </Link>
                  </div>
                </div>
              </Paper>
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
}

export default HighlightsProjects;
<div className="content">
  <div className="left"></div>
  <div className="right"></div>
</div>;
