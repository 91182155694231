import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { StarIcon } from "@heroicons/react/24/outline";
import { Link } from "gatsby";
import moment from "moment";

const Projects = ({ project }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [domainFilter, setDomainFilter] = useState("");
  const [subdomainFilter, setSubdomainFilter] = useState("");

  useEffect(() => {
    if (project.length > 0) {
      setFilteredData(project);
    }
  }, [project]);

  const uniqueValues = [
    ...new Set(project.map((item) => item?.fields?.customfield_10665?.value)),
  ];

  const uniqueStatuses = [
    ...new Set(project.map((item) => item.fields.status.name)),
  ];

  const uniqueDomains = [
    ...new Set(project.map((item) => item?.fields?.customfield_10666?.value)),
  ];

  const uniqueSubdomains = [
    ...new Set(project.map((item) => item?.fields?.customfield_10675?.value)),
  ];

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setSelectedFilter(value);
    filterData(value, statusFilter, domainFilter, subdomainFilter);
  };

  const handleStatusFilterChange = (event) => {
    const value = event.target.value;
    setStatusFilter(value);
    filterData(selectedFilter, value, domainFilter, subdomainFilter);
  };

  const handleDomainFilterChange = (event) => {
    const value = event.target.value;
    setDomainFilter(value);
    filterData(selectedFilter, statusFilter, value, subdomainFilter);
  };

  const handleSubdomainFilterChange = (event) => {
    const value = event.target.value;
    setSubdomainFilter(value);
    filterData(selectedFilter, statusFilter, domainFilter, value);
  };

  const filterData = (region, status, domain, subdomain) => {
    const filtered = project.filter((item) => {
      const matchesRegion = region
        ? item?.fields?.customfield_10665?.value === region
        : true;
      const matchesStatus = status
        ? item?.fields?.status?.name === status
        : true;
      const matchesDomain = domain
        ? item?.fields?.customfield_10666?.value === domain
        : true;
      const matchesSubdomain = subdomain
        ? item?.fields?.customfield_10675?.value === subdomain
        : true;

      return (
        matchesRegion && matchesStatus && matchesDomain && matchesSubdomain
      );
    });

    setFilteredData(filtered);
  };

  return (
    <div className="summary-item">
      <div className="flex">
        <h4 className="no-border line-height40">
          <StarIcon />
          Projects
        </h4>
        <h4 className="no-border">
          <span className="flex no-border filters-wrapper">
            <div className="line-height40">Filter: </div>

            <FormControl
              className="dropdown"
              variant="outlined"
              margin="normal"
              size="small"
            >
              <InputLabel id="domain-filter-label">Domain</InputLabel>
              <Select
                labelId="domain-filter-label"
                value={domainFilter}
                onChange={handleDomainFilterChange}
                label="Filter by Domain"
              >
                <MenuItem value="">All</MenuItem>
                {uniqueDomains.map((domain, index) => (
                  <MenuItem key={index} value={domain}>
                    {domain}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className="dropdown"
              variant="outlined"
              margin="normal"
              size="small"
            >
              <InputLabel id="subdomain-filter-label">Subdomain</InputLabel>
              <Select
                labelId="subdomain-filter-label"
                value={subdomainFilter}
                onChange={handleSubdomainFilterChange}
                label="Filter by Subdomain"
              >
                <MenuItem value="">All</MenuItem>
                {uniqueSubdomains.map((subdomain, index) => (
                  <MenuItem key={index} value={subdomain}>
                    {subdomain}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className="dropdown"
              variant="outlined"
              margin="normal"
              size="small"
            >
              <InputLabel id="filter-label">Country</InputLabel>
              <Select
                labelId="filter-label"
                value={selectedFilter}
                onChange={handleFilterChange}
                label="Filter by Region"
              >
                <MenuItem value="">All</MenuItem>
                {uniqueValues.map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className="dropdown"
              variant="outlined"
              margin="normal"
              size="small"
            >
              <InputLabel id="status-filter-label">Status</InputLabel>
              <Select
                labelId="status-filter-label"
                value={statusFilter}
                onChange={handleStatusFilterChange}
                label="Filter by Status"
              >
                <MenuItem value="">All</MenuItem>
                {uniqueStatuses.map((status, index) => (
                  <MenuItem key={index} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
        </h4>
      </div>
      <div className="detail-item flex no-border scroll">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "35%" }}>Project Name</TableCell>
                <TableCell sx={{ width: "10%" }}>Domain</TableCell>
                <TableCell sx={{ width: "15%" }}>Sub Domain</TableCell>
                <TableCell sx={{ width: "10%" }}>Due Date</TableCell>
                <TableCell sx={{ width: "15%" }}>Country</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((item, index) => (
                <TableRow
                  key={item.id}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? "rgb(227, 227, 227)" : "white",
                  }}
                >
                  <TableCell>
                    <Link to={`/project-details/pmo?id=${item.key}`}>
                      {item.fields.summary}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {item?.fields?.customfield_10666?.value}
                  </TableCell>
                  <TableCell>
                    {item?.fields?.customfield_10675?.value}
                  </TableCell>
                  <TableCell>
                    {moment(item.fields.duedate).format("D MMM YYYY")}
                  </TableCell>
                  <TableCell>
                    {item?.fields?.customfield_10665?.value}
                  </TableCell>
                  <TableCell>{item?.fields?.status?.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Projects;
