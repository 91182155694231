import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AcademicCapIcon } from "@heroicons/react/24/outline";
import { Doughnut } from "react-chartjs-2";
import getStatusCounts from "../../../utils/statusCounts";

const generateRandomColor = () =>
  `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
    Math.random() * 256
  )}, ${Math.floor(Math.random() * 256)}, 0.5)`;

const DemandProjects = ({ demand }) => {
  const [selectedStatus, setSelectedStatus] = useState("Open");
  const [selectedProjects, setSelectedProjects] = useState([]);
  const statusCounts = getStatusCounts(demand, "status");
  const labels = Object.keys(statusCounts);

  const chartData = {
    labels,
    datasets: [
      {
        data: Object.values(statusCounts),
        backgroundColor: Object.values(statusCounts).map(() =>
          generateRandomColor()
        ),
      },
    ],
  };

  const handleChartClick = (_, elements) => {
    if (!elements?.length) return;

    const { index } = elements[0];
    const clickedStatus = labels[index];
    const filteredProjects = demand.filter(
      (project) => project.fields.status.name === clickedStatus
    );

    setSelectedStatus(clickedStatus);
    setSelectedProjects(filteredProjects);
  };

  useEffect(() => {
    const filteredProjects = demand.filter(
      (project) => project.fields.status.name === "Open"
    );
    setSelectedProjects(filteredProjects);
  }, [demand]);

  return (
    <div className="summary-item">
      <div className="flex">
        <h4 className="no-border">
          <AcademicCapIcon />
          Projects in Demand Gate
        </h4>
      </div>

      <div className="detail-item flex no-border">
        <div
          className="demand-table-container"
          style={{
            opacity: selectedProjects.length ? 1 : 0,
            visibility: selectedProjects.length ? "visible" : "hidden",
          }}
        >
          {selectedProjects.length > 0 && (
            <div className="demand-table-wrapper">
              <div className="status">{selectedStatus}</div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Project Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedProjects.map((project, index) => (
                      <TableRow
                        key={project.id}
                        sx={{
                          backgroundColor:
                            index % 2 === 0 ? "rgb(227, 227, 227)" : "white",
                        }}
                      >
                        <TableCell>
                          <a
                            href={`https://lotusretails.atlassian.net/browse/${project.key}`}
                            target="_blank"
                          >
                            {project?.fields?.summary}
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>

        <div
          className="pie-chart-container total-projects"
          style={{ width: "30%" }}
        >
          <div className="total">
            <h2>Total</h2>
            <p>{demand.length}</p>
          </div>
          <Doughnut
            data={chartData}
            options={{
              onClick: handleChartClick,
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DemandProjects;
