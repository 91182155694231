import React from "react";
import { Link } from "gatsby";
import moment from "moment/moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

const sortDataByDueDate = (data) => {
  return data.sort((a, b) => {
    const dateA = moment(a.fields.duedate);
    const dateB = moment(b.fields.duedate);
    return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
  });
};

const getCurrentMonthAndYear = () => {
  const date = new Date();
  return {
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
};

const filterByCurrentMonth = (data) => {
  const { month, year } = getCurrentMonthAndYear();

  return data.filter((item) => {
    const duedate = item.fields.duedate;
    if (!duedate) return false;

    const [dueYear, dueMonth] = duedate.split("-").map(Number);
    return dueYear === year && dueMonth === month;
  });
};

const ThisMonthGoLive = ({ project }) => {
  const filteredData = filterByCurrentMonth(project);
  const sortedData = sortDataByDueDate(filteredData);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Project Name</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.length > 0 ? (
            sortedData.map((i, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor:
                    index % 2 === 0 ? "rgb(227, 227, 227)" : "white",
                }}
              >
                <TableCell>
                  <Link to={`/project-details/pmo?id=${i.key}`}>
                    {i.fields.summary}
                  </Link>
                </TableCell>
                <TableCell>
                  {moment(i.fields.duedate).format("D MMM YYYY")}
                </TableCell>
                <TableCell>{i.fields.status.name}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No projects scheduled for this month
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ThisMonthGoLive;
