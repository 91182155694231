import { adminContextStore } from "../context/store/createStore";
import { SET_JIRA_LIST } from "../context/actions/jira";
import { searchJira } from "../httpRequests/jira";
import PMO_DATA from "../constants/pmoData";

// Helper function to fetch Jira issues with pagination
const fetchAllJiraIssues = async (jql, fields) => {
  // if (process.env.REACT_APP_ENV !== "PROD") {
  //   return PMO_DATA;
  // }

  let startAt = 0;
  const maxResults = 100;
  let issues = [];
  let total = 0;

  do {
    const response = await searchJira({ jql, fields, startAt, maxResults });
    const { issues: fetchedIssues, total: fetchedTotal } = response.data;

    issues.push(...fetchedIssues);
    total = fetchedTotal;
    startAt += maxResults;
  } while (startAt < total);

  return issues;
};

export default async (jql, fields = "") => {
  const { dispatch, getState } = adminContextStore;

  // Ensure fields is an array; if it’s a string, split by commas
  const fieldsArray = Array.isArray(fields)
    ? fields
    : fields.split(",").map((field) => field.trim());

  const cacheKey = `${jql}_${fieldsArray.join(",")}`;
  const oneHour = 60 * 60 * 1000; // one hour in milliseconds

  // Retrieve cache data and timestamp
  const { jiraCache } = getState().jira;
  const cachedData = jiraCache[cacheKey];
  const currentTime = Date.now();

  // Check if data exists in cache and is less than one hour old
  if (
    cachedData &&
    cachedData.timestamp &&
    currentTime - cachedData.timestamp < oneHour
  ) {
    // Dispatch cached data if it's still valid
    dispatch({
      type: SET_JIRA_LIST,
      payload: {
        loading: false,
        jira: cachedData.issues,
      },
    });
    return cachedData.issues;
  }

  // If not in cache or expired, proceed to fetch from the API or use mock data
  dispatch({
    type: SET_JIRA_LIST,
    payload: { loading: true },
  });

  try {
    const issues = await fetchAllJiraIssues(jql, fieldsArray);

    // Cache the fetched data with a timestamp
    dispatch({
      type: SET_JIRA_LIST,
      payload: {
        loading: false,
        jira: issues,
        jiraCache: {
          ...jiraCache,
          [cacheKey]: {
            issues,
            timestamp: currentTime,
          },
        },
      },
    });

    return issues;
  } catch (error) {
    console.error("Error fetching Jira tickets:", error);

    dispatch({
      type: SET_JIRA_LIST,
      payload: { loading: false, error: error.message },
    });

    throw error;
  }
};
