import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ data }) => {
  const options = {
    plugins: {
      datalabels: {
        display: true,
      },
      legend: {
        display: false,
      },
    },
  };

  return <Doughnut options={options} data={data} />;
};

export default DoughnutChart;
